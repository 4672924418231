export const censoredWords = [
  "Japan",
  "Iraqi",
  "Egypt",
  "Thailand",
  "Viet Nam",
  "Icelandic",
  "Colombia",
  "Norway",
  "Myanmar",
  "Kenya",
  "Botswana",
  "Cameroons",
  "Nigeria",
  "Nicaragua",
  "Nicaraguita",
  "Iran",
  "Canada",
  "Ecuador",
  "Morocco",
  "Rumania",
  "Honduras",
  "Chile",
  "Greek",
  "Czechoslovak",
  "Ukrainian",
  "New Zealand",
  "Amsterdamse",
  "Holland",
  "Serbian",
  "Macedonian",
  "Lithuanian",
  "Malta",
  "Cyprus",
  "Georgian",
  "Uzbekistan",
  "Turkmen",
  "Tadjik",
  "Kyrgyzstan",
  "Bahri",
  "Bahrain",
  "Angola",
  "Pakistan",
  "Benin",
  "Eritrean",
  "Central African Republic",
  "Guyana",
  "Chad",
  "Liberia",
  "Mauritania",
  "Burundi",
  "Lesotho",
  "Bhutan",
  "Fiji",
  "Seychelles",
  "Samoa",
  "Laos",
  "Papua New Guinea",
  "North Korean",
  "Djibouti",
  "Mongolian",
  "Mongolia",
  "Cuba",
  "Australian",
  "Australia",
  "Haiti",
  "Bulgaria",
  "Hungarian",

];
