const getDescriptionHintOptions = () => {
  return [
    {
      country: "Indonesia",
      description: `This traditional ensemble music features percussive instruments like metallophones and hand-played drums. It is commonly found in Southeast Asia.`,
      link: "https://en.wikipedia.org/wiki/Gamelan",
      hint: `A traditional ensemble music featuring percussive instruments commonly found in Southeast Asia.`,
    },
    {
      country: "Mexico",
      description: `A folk song derived from a legend about a weeping woman, popularized in Latin America. Often associated with the Day of the Dead.`,
      link: "https://en.wikipedia.org/wiki/La_Llorona_(song)",
      hint: `A folk song about a weeping woman, popularized in Latin America and associated with the Day of the Dead.`,
    },
    {
      country: "Australia",
      description: `An ancient instrument made from a long tube, traditionally used by Indigenous people in ceremonies. Known for its continuous sound through circular breathing.`,
      link: "https://en.wikipedia.org/wiki/Indigenous_music_of_Australia",
      hint: `An ancient instrument made from a long tube, used by Indigenous people in ceremonies, known for circular breathing.`,
    },
    {
      country: "Argentina",
      description: `A beloved folk song written in the 1950s, often performed despite political bans. Popularized by a famous singer who faced tragic consequences.`,
      link: "https://es.wikipedia.org/wiki/Zamba_de_mi_esperanza",
      hint: `A beloved folk song from the 1950s, often performed despite political bans, and popularized by a famous singer.`,
    },
    {
      country: "Ireland",
      description: `A jig believed to have been brought to America by migrants in the 19th century, named after a coat with a forked tail.`,
      link: "https://mikepowernyc.com/2018/10/29/the-swallowtail-jig/",
      hint: `A jig from the 19th century, brought to America by migrants, named after a coat with a forked tail.`,
    },
    {
      country: "Germany",
      description: `A well-known drinking song composed in the 1930s, often sung in a famous beer hall in a European city.`,
      link: "https://en.wikipedia.org/wiki/In_M%C3%BCnchen_steht_ein_Hofbr%C3%A4uhaus",
      hint: `A well-known drinking song from the 1930s, often sung in a famous beer hall.`,
    },
    {
      country: "India",
      description: `A sitar virtuoso known worldwide for his influence on classical music. He was awarded the highest civilian honor in his country in 1999.`,
      link: "https://en.wikipedia.org/wiki/Ravi_Shankar",
      hint: `A sitar virtuoso known worldwide for his influence on classical music. He was awarded the highest civilian honor in his country in 1999.`,
    },
    {
      country: "Tanzania",
      description: `A prominent musician from an ethnic group known for playing the ilimba, a large lamellophone. He gained international attention through collaborations with a famous producer and guitarist.`,
      link: "https://en.wikipedia.org/wiki/Hukwe_Zawose",
      hint: `A prominent musician from an ethnic group known for playing the ilimba, a large lamellophone. He gained international attention through collaborations with a famous producer and guitarist.`,
    },
    {
      country: "Japan",
      description: `A traditional folk song depicting cherry blossoms in spring. It is often sung in international settings as a song representative of its country.`,
      link: "https://en.wikipedia.org/wiki/Sakura_Sakura",
      hint: `A traditional folk song depicting cherry blossoms in spring. It is often sung in international settings as a song representative of its country.`,
    },
    {
      country: "Iraq",
      description: `A genre of maqam music featuring an ensemble with a singer, santur, goblet drum, joza, and sometimes oud. The roots trace back to a historical caliphate.`,
      link: "https://en.wikipedia.org/wiki/Iraqi_maqam",
      hint: `A genre of maqam music featuring an ensemble with a singer, santur, goblet drum, joza, and sometimes oud. The roots trace back to a historical caliphate.`,
    },
    {
      country: "Madagascar",
      description: `Music here is highly melodic with a predominance of chordophones over percussion instruments. It blends widespread commonalities and localized traditions, often using major keys and diatonic scales.`,
      link: "https://en.wikipedia.org/wiki/Music_of_Madagascar",
      hint: `Music here is highly melodic with a predominance of chordophones over percussion instruments. It blends widespread commonalities and localized traditions, often using major keys and diatonic scales.`,
    },
    {
      country: "Brazil",
      description: `A bossa nova and jazz song that became a worldwide hit in the mid-1960s, winning a Grammy for Record of the Year in 1965. It is one of the most recorded pop songs in history.`,
      link: "https://en.wikipedia.org/wiki/The_Girl_from_Ipanema",
      hint: `A bossa nova and jazz song that became a worldwide hit in the mid-1960s, winning a Grammy for Record of the Year in 1965. It is one of the most recorded pop songs in history.`,
    },
    {
      country: "Afghanistan",
      description: `An ensemble exploring the Persian music of this country, rediscovering and revitalizing a forgotten repertoire rich with traditional and sacred heritage.`,
      link: "https://www.womex.com/virtual/sound_of_world/ensemble_kaboul",
      hint: `An ensemble exploring the Persian music of this country, rediscovering and revitalizing a forgotten repertoire rich with traditional and sacred heritage.`,
    },
    {
      country: "Mongolia",
      description: `A form of singing originating in the Altai mountains, where the performer imitates sounds of nature by simultaneously emitting two distinct vocal sounds: a continuous drone and a melody of harmonics.`,
      link: "https://ich.unesco.org/en/RL/mongolian-traditional-art-of-khoomei-00396",
      hint: `A form of singing originating in the Altai mountains, where the performer imitates sounds of nature by simultaneously emitting two distinct vocal sounds: a continuous drone and a melody of harmonics.`,
    },
    {
      country: "France",
      description: `A song written in 1930 that gained popularity through recordings by a famous singer. It has been featured in several classic films, including Casablanca.`,
      link: "https://en.wikipedia.org/wiki/Parlez-moi_d%27amour_(song)",
      hint: `A song written in 1930 that gained popularity through recordings by a famous singer. It has been featured in several classic films, including Casablanca.`,
    },
    {
      country: "Poland",
      description: `A song composed to the words of a poem by a Krakow poet in the 19th century, expressing longing for one's homeland.`,
      link: "https://pl.wikipedia.org/wiki/G%C3%B3ralu,_czy_ci_nie_%C5%BCal%3F",
      hint: `A song composed to the words of a poem by a Krakow poet in the 19th century, expressing longing for one's homeland.`,
    },
    {
      country: "Russia",
      description: `A nineteenth-century folk song about a peddler and a girl, known internationally as the theme tune for a popular video game.`,
      link: "https://en.wikipedia.org/wiki/Korobeiniki",
      hint: `A nineteenth-century folk song about a peddler and a girl, known internationally as the theme tune for a popular video game.`,
    },
    {
      country: "China",
      description: `A folk song from the Jiangnan region, dating back to the 18th century. It has been used in various international events and gained popularity both domestically and abroad.`,
      link: "https://en.wikipedia.org/wiki/Mo_Li_Hua",
      hint: `A folk song from the Jiangnan region, dating back to the 18th century. It has been used in various international events and gained popularity both domestically and abroad.`,
    },
    {
      country: "Philippines",
      description: `A traditional folk dance involving bamboo poles and dancers who step over and between the poles. It is accompanied by a type of serenade music played by an ensemble of stringed instruments.`,
      link: "https://en.wikipedia.org/wiki/Tinikling",
      hint: `A traditional folk dance involving bamboo poles and dancers who step over and between the poles. It is accompanied by a type of serenade music played by an ensemble of stringed instruments.`,
    },
    {
      country: "Israel",
      description: `"Hava Nagila" is one of the first modern Jewish folk songs in the Hebrew language. It went on to become a staple of band performers at Jewish weddings and bar/bat(b'nei) mitzvah celebrations.\nThe melody is based on a Hassidic Nigun. It was composed in 1918 to celebrate the Balfour Declaration and the British victory over the Ottomans in 1917. It was first performed in a mixed choir concert in Jerusalem.`,
      link: "https://en.wikipedia.org/wiki/Hava_Nagila",
      hint: `The melody is based on a Hassidic Nigun. It was composed in 1918 to celebrate the Balfour Declaration and the British victory over the Ottomans in 1917.`,
    },
    {
      country: "Cuba",
      description: `A patriotic song that uses a poem by a famous poet for its lyrics. It became internationally known through various covers and has been popularized since the early 20th century.`,
      link: "https://en.wikipedia.org/wiki/Guantanamera",
      hint: `A patriotic song that uses a poem by a famous poet for its lyrics. It became internationally known through various covers and has been popularized since the early 20th century.`,
    },
    {
      country: "Egypt",
      description: `An improvisation on the lyre using an ancient pentatonic scale, believed to be around 3000 years old. Although no written music from ancient times has survived, some musical scales have been reconstructed from ancient instruments.`,
      link: "https://michaellevy.bandcamp.com/track/echoes-of-ancient-egypt-improvisation-on-an-ancient-egyptian-scale",
      hint: `An improvisation on the lyre using an ancient pentatonic scale, believed to be around 3000 years old. Although no written music from ancient times has survived, some musical scales have been reconstructed from ancient instruments.`,
    },
    {
      country: "Thailand",
      description: `A type of ensemble in classical music featuring wind and percussion instruments. It is used for interpreting sacred compositions and accompanies traditional theatrical and dance forms.`,
      link: "https://en.wikipedia.org/wiki/Piphat",
      hint: `A type of ensemble in classical music featuring wind and percussion instruments. It is used for interpreting sacred compositions and accompanies traditional theatrical and dance forms.`,
    },
    {
      country: "Vietnam",
      description: `This music is considered "entertainment" music similar to private concerts or radio, rather than for ceremonial occasions. It features the dàn tranh, a 17-string zither, and the ty bà lute.`,
      link: "https://folkways.si.edu/viet-nam-tradition-of-the-south/world/music/album/smithsonian",
      hint: `This music is considered "entertainment" music similar to private concerts or radio, rather than for ceremonial occasions. It features the dàn tranh, a 17-string zither, and the ty bà lute.`,
    },
    {
      country: "Kazakhstan",
      description: `A traditional instrument played by strumming or plucking the strings, occasionally tapping on the surface. Modern versions often use nylon strings. One famous player, a composer, had a major influence on the development of the country's musical culture.`,
      link: "https://en.wikipedia.org/wiki/Dombra",
      hint: `A traditional instrument played by strumming or plucking the strings, occasionally tapping on the surface. Modern versions often use nylon strings. One famous player, a composer, had a major influence on the development of the country's musical culture.`,
    },
    {
      country: "Bulgaria",
      description: `A composer and arranger who founded the first professional state-supported ensemble for folk song and dance in the country. He pioneered a style fusing folk elements with western classical forms.`,
      link: "https://en.wikipedia.org/wiki/Filip_Kutev",
      hint: `A composer and arranger who founded the first professional state-supported ensemble for folk song and dance in the country. He pioneered a style fusing folk elements with western classical forms.`,
    },
    {
      country: "Iceland",
      description: `Ólafur Liljurós is the fourth piece in the Icelandic Folk music album, released in 1996 by Magnús Þór Sigmundsson. However, Liljurós is a traditional Viking folk story dating back to the 9th century. Storyline and musical aspects were combined in the early 14th century to compose Ólafur Liljurós. The piece recounts the classic tale of a man traveling to visit his mother. Whilst on his journey, he encounters an elf who seduces, kisses, and kills the traveler. The piece utilizes the six main musical elements to convey the story. Duration, pitch, and timbre are most prominently used to express the storyline and characteristics of the folk song. Folk music gives an insight into the historical and personal events of the time period. Passed down from generation to generation, folk music has survived through to modern-day Iceland and still gives a strong depiction of the country. Ólafur Liljurós is a perfect example of Icelandic music, the simplistic melody carries the classic storyline.`,
      link: "https://icelandicmusic11.weebly.com/oacutelafur-liljuroacutes.html",
      hint: `A traditional Viking folk story about a man traveling to visit his mother, encountering an elf along the way.`,
    },
    {
      country: "Spain",
      description: `Isaac Manuel Francisco Albéniz y Pascual was a Spanish virtuoso pianist, composer, and conductor. He is one of the foremost composers of the Post-Romantic era who also had a significant influence on his contemporaries and younger composers. He is best known for his piano works based on Spanish folk music idioms. Transcriptions of many of his pieces, such as Asturias (Leyenda), Granada, Sevilla, Cadiz, Córdoba, Cataluña, Mallorca, and Tango in D, are important pieces for classical guitar, though he never composed for the guitar. The personal papers of Albéniz are preserved in, among other institutions, the Library of Catalonia.`,
      link: "https://en.wikipedia.org/wiki/Isaac_Alb%C3%A9niz",
      hint: `A Spanish virtuoso pianist and composer known for his piano works based on Spanish folk music idioms. His music is commonly played on other instruments such as the guitar.`,
    },
    {
      country: "Colombia",
      description: `"Colombia Tierra Querida" (translation "Colombia dear land") is a song written by Lucho Bermúdez in the Colombian cumbia genre. It is also the name of the 1970 album by Bermúdez, released on CBS Records, that introduced the song. The song was popularized in 1970 with a version of the song recorded by Bermúdez and his orchestra with vocals by Matilde Diaz. Since its release, the song has been covered by many artists and is one of the most widely recognized songs of Colombian folk music. It has been described as a "second national anthem" by multiple Colombian media.`,
      link: "https://en.wikipedia.org/wiki/Colombia_Tierra_Querida",
      hint: `A song written by Lucho Bermúdez in the local cumbia genre, considered a "second national anthem" by multiple media.`,
    },
    {
      country: "Peru",
      description: `"El Cóndor Pasa" is an orchestral musical piece from the zarzuela El Cóndor Pasa by the Peruvian composer Daniel Alomía Robles, written in 1913 and based on traditional Andean music, specifically folk music from this country. Since then, it has been estimated that, around the world, more than 4,000 versions of the melody have been produced, along with 300 sets of lyrics. In 2004, this country declared this song to be a part of their national cultural heritage. This song is now considered the second national anthem of this country. It is the best-known song from this country in the English-speaking world, due to a 1970 cover by Simon & Garfunkel on their Bridge over Troubled Water album. Their version is called "El Cóndor Pasa (If I Could)".`,
      link: "https://en.wikipedia.org/wiki/El_C%C3%B3ndor_Pasa_(song)",
      hint: `An orchestral piece from a zarzuela by a composer, based on traditional Andean music, considered the second national anthem of its country.`,
    },
    {
      country: "Norway",
      description: `The hardingfele (fiddle from Hardanger) has been the principal stringed instrument of the fiddle tradition in the region of Agder in southernmost Norway since the 20th century. Similar in design to a violin, the hardingfele has four strings that are bowed and four or five understrings, or sympathetic strings, creating an echo-like sound. This recording provides examples of the history and variation of violin music in Norway. Of interest are the distinct differences in sound between the fiddle, the hardingfele, and the lesser-known setedalsfele (a cross between a hardingfele and violin, named after the Setesdal district). Vidar Lande performs traditional Norwegian dance pieces such as gangar, sprigar, and halling and the newer gammeldans songs (old-time dance) such as waltz and polkas. Lande grew up in Agder and Bygland and learned to play the hardingfele from his uncle. His interest in native Norwegian fiddle music has resulted in his transcription and preservation of songs of this unique style of music. The liner notes give historical and technical information about each song.`,
      link: "https://folkways.si.edu/norway-fiddle-and-hardanger-fiddle-music-from-agder/world/music/album/smithsonian",
      hint: `A traditional stringed instrument similar to a violin, used in the fiddle tradition of the southernmost region of its country, known for its echo-like sound.`,
    },
    {
      country: "El Salvador",
      description: `El Carbonero is a folk song written by Salvadoran singer-songwriter Francisco Antonio Lara Hernández, also known as "Pancho Lara". The song is considered by many Salvadorans as the second national anthem of the El Salvador.`,
      link: "https://es.wikipedia.org/wiki/El_Carbonero",
      hint: `A folk song written by Francisco Antonio Lara Hernández, considered by many as the second national anthem of this central American country.`,
    },
    {
      country: "Myanmar (Burma)",
      description: `The saung (Burmese: စောင်း, MLCTS caung: IPA: [sáʊɰ̃ɡaʊʔ]; also known as the saung-gauk (စောင်းကောက်), Burmese harp, Burma harp, or Myanmar harp), is an arched harp used in traditional Burmese music. The saung is regarded as a national musical instrument of Burma.`,
      link: "https://en.wikipedia.org/wiki/Saung",
      hint: `An arched harp used in traditional music, regarded as a national musical instrument of its country.`,
    },
    {
      country: "Kenya",
      description: `David Nzomo had already recorded several albums of Kenyan music for Folkways, including 1965’s Songs from Kenya, 1970’s African Rhythms: Songs from Kenya, and 1973’s African Politics: More Songs from Kenya, when he recorded this set of traditional Kenyan work and dance songs. In his liner notes, Nzomo writes, “Generally, African traditional music is functional. Thus songs are either Work Songs, Dance Songs, Play Songs, etc.” Before he performs each song, Nzomo explains its specific function, though some songs are said to be useful for a variety of functions. Nzomo is accompanied by a chorus of singers on most songs, many of which use a call-and-response form. Liner notes include a dedication, a foreword, and lyrics.`,
      link: "https://folkways.si.edu/david-nzomo/work-and-dance-songs-from-kenya/world/music/album/smithsonian",
      hint: `A set of traditional work and dance songs recorded by David Nzomo, often using a call-and-response form.`,
    },
    {
      country: "Botswana",
      description: `While traveling across Botswana in 1983, ethnomusicologist Elizabeth N. Wood captured music from Tswana–Sotho tribal descendants and Basarwa Bushmen, whose songs cover themes such as war, hunting, farming, school, and religion. Singing styles range from unison to harmony to call–and–response and are often accompanied by clapping and stomping, with an occasional whistle or drum. Instrumental tracks feature instruments such as the mbira (thumb piano) and lengope (mouth bow). Liner notes include a description of Botswana’s geography, ethnic groups, music, and dance. Lyrics and track notes are also included.`,
      link: "https://folkways.si.edu/traditional-music-of-botswana-africa-a-journey-with-tape-recorder-along-southern-botswana-from-mochudi-to-kang/world/album/smithsonian",
      hint: `Music captured by ethnomusicologist Elizabeth N. Wood from Tswana-Sotho tribal descendants and Basarwa Bushmen, covering themes like war, hunting, and farming.`,
    },
    {
      country: "Nigeria",
      description: `Recorded in the field by Paul Newman, Eric Davidson, and Lyn Davidson, Music from the Villages of Northeastern Nigeria explores the music of five distinct ethnic groups: the Bura, Ga’anda, Bolewa, Tangale, and Tera. Published as part of the Asch Mankind Series in 1971, the collection’s thirty-one tracks tour the variety of musical traditions practiced in a region of marked cultural and linguistic diversity. In his review of the record, Nigerian musicologist Aken Euba wrote that these tracks “provide a good cross-section of the music of five African ethnic groups and is, from this point of view, useful for the general student of African Culture.” Liner notes include ethnographic profiles of the five groups, a map of their geographical distribution, a diagram of their linguistic classification, photographs, and annotations of each track which include partial translations.`,
      link: "https://folkways.si.edu/music-from-the-villages-of-northeastern-nigeria/world/album/smithsonian",
      hint: `Music recorded from five distinct ethnic groups, exploring a variety of musical traditions in a culturally and linguistically diverse region.`,
    },
    {
      country: "Cameroon",
      description: `The Bulu are one of the Fang-speaking peoples living in southern Cameroon, Equatorial Guinea, and northern Gabon—a region renowned for its rainforests and mountains. Bulu Songs from the Cameroons includes songs sung by the mbon mvet, a traveling storyteller and musician who spreads news throughout the region and who plays the mvet, a stringed instrument made from the frond of a raffia palm and gourds. Liner notes include a description of Bulu culture, track notes, notes on the pronunciation of Bulu words, and an illustration of the mvet.\nWOMEN'S DANCE SONG, with female voices and handclapping. In some dances of the Bulu, handclapping or stick-clapping provides the entire percussion accompaniment. This is particularly true in women's gatherings in which men do not participate. The drummers, as in most of West Africa, are always men.`,
      link: "https://folkways.si.edu/bulu-songs-from-the-cameroons/world/music/album/smithsonian",
      hint: `Songs from the Bulu people, including those sung by a traveling storyteller and musician who plays a stringed instrument made from a raffia palm and gourds.`,
    },
    {
      country: "South Africa",
      description: `Solomon Popoli Linda (1909 – 8 September 1962), also known as Solomon Ntsele ("Linda" was his clan name), was a South African musician, singer and composer best known as the composer of the song "Mbube", which later became the pop music success "The Lion Sleeps Tonight", and gave its name to the Mbube style of isicathamiya a cappella later popularized by Ladysmith Black Mambazo.`,
      link: "https://en.wikipedia.org/wiki/Solomon_Linda",
      hint: `A musician, singer, and composer best known for the song "Mbube," which became "The Lion Sleeps Tonight," popularizing a style of a cappella.`,
    },
    {
      country: "Zimbabwe",
      description: `Stella Chiweshe (also Stella Rambisai Chiweshe, Stella Rambisai Chiweshe Nekati, Mbuya Stella Chiweshe, or Stella Nekati Chiweshe; 8 July 1946 – 20 January 2023) was a Zimbabwean musician. She was known internationally for her singing and playing of the mbira dzavadzimu, a traditional instrument of the Shona people of Zimbabwe. She was one of few female players, and learned to play from 1966 to 1969, when other women did not.`,
      link: "https://en.wikipedia.org/wiki/Stella_Chiweshe",
      hint: `A musician known internationally for singing and playing the mbira dzavadzimu, a traditional instrument of the Shona people.`,
    },
    {
      country: "Iran",
      description: `"Ey Irân" (Persian: ای ایران, pronounced [ʔej ʔiːˈɾɒːn] | lit. O Iran!) is an Iranian patriotic song that serves as the country's unofficial national anthem. The music was composed by Ruhollâh Xâleqi, and the lyrics were written by Hossein Gol-e-Golâb. This anthem was first performed by Qolâm-Hossein Banân.`,
      link: "https://en.wikipedia.org/wiki/Ey_Iran",
      hint: `A patriotic song that serves as an unofficial national anthem, with music composed by Ruhollâh Xâleqi and lyrics by Hossein Gol-e-Golâb.`,
    },
    {
      country: "Democratic Republic of the Congo",
      description: `"Indépendance Cha Cha" (French; "Independence cha cha") was a song performed by Joseph Kabasele (best known by his stage name Le Grand Kallé) from the group L'African Jazz in the popular African Rumba style. The song has been described as "Kabasele's most memorable song" and one of the first Pan-African hits. The song was composed and first performed in 1960, the so-called Year of Africa, to celebrate the imminent independence of the Belgian Congo (the modern-day Democratic Republic of the Congo). The song achieved considerable successes and remains the most internationally best-known examples of Congolese Rumba.`,
      link: "https://en.wikipedia.org/wiki/Ind%C3%A9pendance_Cha_Cha",
      hint: `A song performed by Joseph Kabasele in the popular African Rumba style, composed to celebrate independence from Belgium.`,
    },
    {
      country: "Canada",
      description: `The Inuit of the Canadian Northwest Territories follow two seasonal social patterns: hunting with families in the summer, and gathering for trade and intense spiritual activities with large communities in the winter. Traditionally, when these groups reunited at the start of the long, dark winter, they exchanged songs. Each person is believed to be a composer and performer; each song is a gift to be reciprocated, and the sharing of songs and dances was a way to strengthen the social fabric. Many songs on this album are about hunting, but it was forbidden to exchange songs while hunting, or even while outdoors. The Inuit believed that under an open sky, “a spirit could steal the words and by seizing the breath of the singer, rob him of his life.”`,
      link: "https://folkways.si.edu/canada-music-of-the-inuit-the-copper-eskimo-tradition/world/music/album/smithsonian",
      hint: `Music from a northern-living indigenous group that follows seasonal social patterns and exchanges songs during large winter gatherings.`,
    },
    {
      country: "United States",
      description: `"This Land Is Your Land" is a song by American folk singer Woody Guthrie. It is one of the United States' most famous folk songs. Its lyrics were written in 1940 in critical response to Irving Berlin's "God Bless America". Its melody is based on a Carter Family tune called "When the World's on Fire". When Guthrie was tired of hearing Kate Smith sing "God Bless America" on the radio in the late 1930s, he sarcastically called his song "God Blessed America for Me" before renaming it "This Land Is Your Land".`,
      link: "https://en.wikipedia.org/wiki/This_Land_Is_Your_Land",
      hint: `A song by Woody Guthrie, written in response to a tune by Irving Berlin, with a melody based on a Carter Family tune called "When the World's on Fire".`,
    },
    {
      country: "Ecuador",
      description: `Clay pot is a musical composition for dancing, known as "the unofficial anthem of Ecuador". The lyrics refer to the country's ancient mortuary rituals as a symbol of the desire to return to it Mother Earth and it was written in 1950 by poets Jorge Carrera Andrade, Jorge Enrique Adoum, Hugo Alemán and the painter Jaime Valencia. The melody of the song was created by Gonzalo Benítez.`,
      link: "https://es.wikipedia.org/wiki/Vasija_de_barro",
      hint: `A musical composition known as "the unofficial anthem" of its country, with lyrics referring to ancient mortuary rituals.`,
    },
    {
      country: "Morocco",
      description: `This 1988 recording focuses on the Arabic tradition in Moroccan music. According to the liner notes, the two forms of l-'asri modern music and sh-sha'abi popular/people’s music have wide appeal around the country as well as in all segments of the population. Although they share similar modal structures, they are quite different formally and rhythmically.`,
      link: "https://folkways.si.edu/morocco-arabic-traditional-music/world/music/album/smithsonian",
      hint: `A recording focusing on the Arabic tradition, highlighting modern and popular music forms with wide appeal.`,
    },
    {
      country: "Latvia",
      description: `Blow the winds (limp Pugõ tour — 'blow, wind') is old Liv wedding a song that is folklore and is considered Latvian folk song. It is believed to have originated at. Daugava leva In Vidzeme. Liv the guy went by boat to his beloved to Kurzemi, wanting to get married.`,
      link: "https://lv.wikipedia.org/wiki/P%C5%ABt,_v%C4%93ji%C5%86i_(dziesma)",
      hint: `An old wedding song, considered a folk song, believed to have originated in the Vidzeme region.`,
    },
    {
      country: "Rwanda",
      description: `Inka Yi Abajiji (The Cows of the Abajiji Family). Sung by the women and girls of the house of the queen-mother at Shyogwe. The song is from the time of Musinga. The theme is pastoral, telling about the cows with the long horns. Each one's name is called, and the song narrates the places where the cattle have grazed and the calves that have been born.`,
      link: "https://folkways.si.edu/songs-of-the-watutsi/world/music/album/smithsonian",
      hint: `A pastoral song sung by women and girls, telling about cows with long horns and narrating where the cattle have grazed and the calves that have been born.`,
    },
    {
      country: "Romania",
      description: `This collection of Romanian folk music showcases two of the country’s most popular musical forms. Considered as belonging to everybody, the doina is “..a song of love, a pantheistic poem, a fighting song, or an outcry against injustice or the foreign ruler.” The hora, which is said to be known by more than 1546 names, is an ageless and genderless circle dance that can be found throughout Romania.`,
      link: "https://folkways.si.edu/folk-music-of-rumania/world/album/smithsonian",
      hint: `A collection of folk music featuring the doina, a versatile song form, and the hora, an ageless and genderless circle dance.`,
    },
    {
      country: "Honduras",
      description: `As liner notes author Doris Stone explains, “Honduras was a meeting ground of nations from its earliest pre-Columbian days. ” Spanish colonial, African, and Native American influences blend not only in the music and dance styles but in the development of musical instruments, especially the marimba. Released in 1955, Songs and Dances of Honduras features ballads such as the corrido and dances such as the waltz, rumba, fandango, and the local sique. Liner notes include a brief cultural history of Honduras, lyrics, track notes, and detailed descriptions of instruments and types of dances.`,
      link: "https://folkways.si.edu/songs-and-dances-of-honduras/world/music/album/smithsonian",
      hint: `A collection of songs and dances blending Spanish colonial, African, and Native American influences, featuring the marimba.`,
    },
    {
      country: "South Korea",
      description: `"Cherry Blossom Ending" (Korean: 벚꽃 엔딩; RR: Beotkkot Ending) is a song by South Korean indie band Busker Busker, from their debut studio album Busker Busker 1st Album. The song was written by band member Jang Beom-jun [ko] and was released on March 29, 2012, by CJ E&M Music. The song is well known for re-entering the music charts every spring in South Korea, earning the nicknames "Spring Carol" and "Cherry Blossom Zombie". As of 2021, "Cherry Blossom Ending" has received over 8,000,000 digital downloads according to Gaon, making it the best-selling single in the country.`,
      link: "https://en.wikipedia.org/wiki/Cherry_Blossom_Ending",
      hint: `A song by an indie band, known for re-entering the music charts every spring, earning nicknames like "Spring Carol" and "Cherry Blossom Zombie."`,
    },
    {
      country: "Mozambique",
      description: `A luta continua (in English: the struggle continues) was the rallying cry of the FRELIMO movement during Mozambique's war for independence. The phrase is in the Portuguese language (the official language of the former Portuguese colony) a slogan coined by the first president of FRELIMO, Dr. Eduardo Chivambo Mondlane, which he used to rally the population in the liberated zones of Mozambique during the armed struggle against Portuguese colonial rule.`,
      link: "https://en.wikipedia.org/wiki/A_luta_continua",
      hint: `A rallying cry of the FRELIMO movement during the country's war for independence, coined by its first president.`,
    },
    {
      country: "Portugal",
      description: `"Grândola, Vila Morena" (English: Grândola, Swarthy Town) is a Portuguese song by singer-songwriter José Afonso, recorded in 1971. It was originally released in Afonso's 1971 album Cantigas do Maio and later released in an EP of the same name in 1973, and as a single in 1977. \n "Grândola, Vila Morena" became an iconic song in Portugal after being used as a radio-broadcast signal by the Portuguese Armed Forces Movement during their military coup operation in the morning of 25 April 1974, which led to the Carnation Revolution and the transition to democracy in Portugal. It has since been considered a symbol of the revolution and anti-fascism.`,
      link: "https://en.wikipedia.org/wiki/Gr%C3%A2ndola,_Vila_Morena",
      hint: `A song by José Afonso, used as a radio-broadcast signal during a military coup that led to the Carnation Revolution and the transition to democracy.`,
    },
    {
      country: "Chile",
      description: `"Gracias a la vida" (Spanish: "Thanks to Life") is a song written, composed and performed by Chilean Violeta Parra, one of the artists who was part of the movement and musical genre known as the Nueva Canción. Parra composed "Gracias a la vida" in La Paz in 1966. It was included on Las Últimas Composiciones, the last album Parra published before dying by suicide in 1967. The song is one of Parra's most renowned. It is performed throughout the world and remains one of the most covered Latin American songs in history.`,
      link: "https://en.wikipedia.org/wiki/Gracias_a_la_vida",
      hint: `A song by Violeta Parra, part of the Nueva Canción movement, known as one of the most covered Latin American songs in history.`,
    },
    {
      country: "Greece",
      description: `"Zorba's Dance" (Greek: Ο Χορός Του Ζορμπά) is an instrumental by Greek composer Mikis Theodorakis. The song featured for the dance, which has become known as sirtaki, in the 1964 film Zorba the Greek, for which Theodorakis wrote the soundtrack, and became renowned around the world. It is now commonly played and danced to in Greek tavernas. The film's track has since been recorded as a standalone song by many different musicians from around the world.`,
      link: "https://en.wikipedia.org/wiki/Zorba%27s_Dance",
      hint: `An instrumental by Mikis Theodorakis featured in a famous film produced in the country, associated with the dance known as sirtaki.`,
    },
    {
      country: "Nepal",
      description: `Resham Firiri (Nepali: रेशम फिरिरि) is a traditional Nepali folk song, composed by Buddhi Pariyar and originally performed by Sunder Shrestha and Dwarika Lal Joshi in 1969. Due to sudden rise in popularity, it became one of the most widely known and performed songs in Nepal. It is often played on the sarangi, a native instrument, as street music.`,
      link: "https://en.wikipedia.org/wiki/Resham_Firiri",
      hint: `A traditional folk song, composed by Buddhi Pariyar, widely known and performed, often played on the sarangi.`,
    },
    {
      country: "Belarus",
      description: `Kupalinka is a popular Belarusian song described as a “musical business card of Belarus”. \n The song's lyrical heroine, the Kupala Night Maiden is “weeding a rose, piercing her white hands” and “plucking flowers, weaving wreaths, and shedding tears”. \n It is considered that the song has become “the national personification of Belarus as a country with a beautiful and sad woman’s face”.`,
      link: "https://en.wikipedia.org/wiki/Kupalinka",
      hint: `A popular song described as a “musical business card,” featuring the Kupala Night Maiden, symbolizing the country with a beautiful and sad woman’s face.`,
    },
    {
      country: "Czechia",
      description: `"Beer Barrel Polka", originally in Czech "Škoda lásky", also known as "The Barrel Polka", "Roll Out the Barrel", or "Rosamunde", is a 1927 polka composed by Czech musician Jaromír Vejvoda. Lyrics were added in 1934, subsequently gaining worldwide popularity during World War II as a drinking song.`,
      link: "https://en.wikipedia.org/wiki/Beer_Barrel_Polka",
      hint: `A 1927 polka composed by Jaromír Vejvoda, known for gaining worldwide popularity during World War II as a drinking song.`,
    },
    {
      country: "Sri Lanka",
      description: `Sri Lankabhimanya Wannakuwattawaduge Don Albert Perera (Sinhala: වන්නකුවත්තවඩුගේ දොන් ඇල්බට් පෙරේරා; Tamil: டபிள்யூ. டி. அமரதேவா; 5 December 1927 – 3 November 2016), better known by his adopted name Amaradeva, was a prominent Sri Lankan Sinhalese vocalist, violinist and composer. Primarily using traditional instruments like sitars, tablas and harmoniums, he incorporated Sinhala folk music with Indian ragas in his work. Many consider his contribution to the development of Sinhala music as unmatched; hence, he is occasionally cited as the "Maestro of Sri Lankan Music"`,
      link: "https://en.wikipedia.org/wiki/W._D._Amaradeva",
      hint: `A prominent vocalist, violinist, and composer known for incorporating Sinhala folk music with Indian ragas, often cited as the "Maestro of Music" in his country.`,
    },
    {
      country: "Türkiye",
      description: `"Kâtibim" ("my clerk"), or "Üsküdar'a Gider İken" ("while going to Üsküdar") is a Turkish folk song about someone's clerk (kâtip) as they travel to Üsküdar. The tune is a famous Istanbul türkü, which is spread beyond Turkey in many countries, especially in the Balkans.`,
      link: "https://en.wikipedia.org/wiki/K%C3%A2tibim",
      hint: `A folk song about someone's clerk as they travel to Üsküdar, a famous tune that has spread to many countries, especially in the Balkans.`,
    },
    {
      country: "Austria",
      description: `"Silent Night" (German: "Stille Nacht, heilige Nacht") is a popular Christmas carol, composed in 1818 by Franz Xaver Gruber to lyrics by Joseph Mohr in Oberndorf bei Salzburg, Austria. It was declared an intangible cultural heritage by UNESCO in 2011. The song was first recorded in 1905 and has remained a popular success, appearing in films and multiple successful recordings, as well as being quoted in other musical compositions. It is the world's most recorded Christmas song, with more than 137,000 known recordings.`,
      link: "https://en.wikipedia.org/wiki/Silent_Night",
      hint: `A popular Christmas carol composed in 1818, declared an intangible cultural heritage by UNESCO, and the world's most recorded Christmas song.`,
    },
    {
      country: "Switzerland",
      description: `The song from Vreneli ab em Guggisberg, Guggisberglied for short, also called Guggisbergerlied or Altes Guggisbergerlied, is probably the oldest still known Swiss folk song. It was first mentioned in 1741, and the oldest surviving text variant dates from 1764. \n The song is about a “Vreneli” (Swiss German for “Verena”) from Guggisberg, who longs for his chosen one “Simes Hans-Joggeli” (Simons Hans-Jakob) and finds consolation for his enduring love in the image of the constantly turning mill wheel.`,
      link: "https://de.wikipedia.org/wiki/Guggisberglied",
      hint: `Probably the oldest known folk song from this country, first mentioned in 1741, about a "Vreneli" who longs for "Simes Hans-Joggeli" and finds consolation in the image of a turning mill wheel.`,
    },
    {
      country: "Ukraine",
      description: `Oh, the Red Viburnum in the Meadow (Ukrainian: Ой у лузі червона калина, romanized: Oi u luzi chervona kalyna) is a Ukrainian patriotic march first published in 1875 by Volodymyr Antonovych and Mykhailo Drahomanov. It was written in a modern treatment by the composer Stepan Charnetsky in 1914, in honor and memory of the Sich Riflemen of the First World War. The song has many variations. \n Following the 2014 annexation by Russia of the Ukrainian Crimean peninsula, and then the 2022 Russian invasion of Ukraine, singing "nationalist anthems" such as Chervona Kalyna in Crimea became punishable by fines and imprisonment.`,
      link: "https://en.wikipedia.org/wiki/Oi_u_luzi_chervona_kalyna",
      hint: `A patriotic march first published in 1875 and written in a modern treatment in 1914 to honor the Sich Riflemen of the First World War.`,
    },
    {
      country: "Jamaica",
      description: `Robert Nesta Marley OM (6 February 1945 – 11 May 1981) was a Jamaican reggae singer, guitarist, and songwriter. Considered one of the pioneers of the genre, Marley fused elements of reggae, ska, and rocksteady in his music and was renowned for his distinctive vocal and songwriting style. Marley's contribution to music increased the visibility of Jamaican music worldwide and made him a global figure in popular culture.`,
      link: "https://en.wikipedia.org/wiki/Bob_Marley",
      hint: `A reggae singer, guitarist, and songwriter known for fusing elements of reggae, ska, and rocksteady, and increasing the visibility of his country's music worldwide.`,
    },
    {
      country: "New Zealand",
      description: `"Pōkarekare Ana" is a traditional New Zealand love song, probably communally composed about the time World War I began in 1914. The song is written in Māori and has been translated into English. It enjoys widespread popularity in New Zealand as well as some popularity in other countries.`,
      link: "https://en.wikipedia.org/wiki/P%C5%8Dkarekare_Ana",
      hint: `A traditional love song, probably composed around World War I, written in Māori and widely popular both domestically and internationally.`,
    },
    {
      country: "Malaysia",
      description: `Dato' Sri Hajah Siti Nurhaliza Tarudin SSAP, DIMP, JSM, SAP, PMP, AAP (Jawi: سيتي نورهاليزا بنت تارودين; IPA: [ˈsiti nʊrhaˈliza ˈbinti taˈrudɪn]; born January 11, 1979) is a Malaysian singer, songwriter, actress and businesswoman with more than 300 local and international awards. She made her debut after she won a local singing competition show, Bintang HMI in 1995 when she was 16. Her debut single, Jerat Percintaan, won the 11th Anugerah Juara Lagu and another two awards for Best Performance and Best Ballad. Her debut album as of 2005 has sold more than 800,000 units in Malaysia. She has recorded and sung in multiple languages, including Malaysian, Javanese, English, Japanese, and Mandarin.`,
      link: "https://en.wikipedia.org/wiki/Siti_Nurhaliza",
      hint: `A singer, songwriter, actress, and businesswoman with over 300 awards, known for her debut single "Jerat Percintaan" and her recordings in multiple languages.`,
    },
    {
      country: "Ghana",
      description: `Nana Kwame Ampadu (31 March 1945 – 28 September 2021) was a Ghanaian musician and composer credited with numerous popular highlife tracks and he is known to have composed over 800 songs. He was also known as Adwomtofo Nyinaa Hene. Ampadu was the lead singer, chief songwriter, and founder of the "African Brothers Band". He is regarded as a pioneer of highlife music and one of the most illustrious Ghanaian musicians of the 20th century.`,
      link: "https://en.wikipedia.org/wiki/Nana_Ampadu",
      hint: `A musician and composer credited with over 800 songs, known as the founder of the "African Brothers Band" and a pioneer of highlife music.`,
    },
    {
      country: "Bolivia",
      description: `"Llorando se fue" (English: They left in tears) is a Bolivian folk song recorded by Los Kjarkas in 1981 on the album Canto a la mujer de mi pueblo and released as a B-side of the "Wa ya yay" single in 1982. The song has been very popular in Latin America since the 1980s and has been covered several times. It obtained international fame with artists such as Wilkins in 1984, Argentine singer Juan Ramón in 1985, Brazilian singer Márcia Ferreira in 1986 and the French-Brazilian pop group Kaoma in 1989.`,
      link: "https://en.wikipedia.org/wiki/Llorando_se_fue",
      hint: `A folk song recorded by Los Kjarkas in 1981, gaining international fame through various covers in the 1980s.`,
    },
    {
      country: "Paraguay",
      description: `"Recuerdos de Ypacaraí" is a Guarani song with lyrics by Zulema de Mirkin and music by Demetrio Ortiz. With this song of wide worldwide circulation, Demetrio Ortiz is known and contributes to the knowledge of the music of Paraguay as well as of Lake Ypacaraí and the city of San Bernardino.`,
      link: "https://es.wikipedia.org/wiki/Recuerdos_de_Ypacara%C3%AD",
      hint: `A Guarani song with lyrics by Zulema de Mirkin and music by Demetrio Ortiz, widely circulated and contributing to the knowledge of local music and landmarks.`,
    },
    {
      country: "United Kingdom",
      description: `"Land of Hope and Glory" is a British patriotic song, with music by Edward Elgar, written in 1901 and with lyrics by A. C. Benson later added in 1902. \n In the United States, Canada and the Philippines, the instrumental version of this song is traditionally associated with high school and college (university) graduation ceremonies. It is played as a processional or recessional often omitting all but the movement of the song over which the chorus is traditionally sung. During ceremonies for larger schools this piece (or the truncated version, if it is being used) is played repeatedly. It may be played for as long as the graduates are marching or walking, which can be longer than some symphonies.`,
      link: "https://en.wikipedia.org/wiki/Land_of_Hope_and_Glory",
      hint: `A patriotic song with music by Edward Elgar, associated with graduation ceremonies in several countries.`,
    },
    {
      country: "Sweden",
      description: `The Slängpolska (Swedish plural slängpolskor; "släng" Swed. for "toss, throw, chuck" and directly cognate to the English "to sling") is a Swedish folk dance and sometimes also the description of certain folk music tunes.`,
      link: "https://en.wikipedia.org/wiki/Sl%C3%A4ngpolska",
      hint: `A folk dance with a name meaning "toss, throw, chuck," also used to describe certain folk music tunes.`,
    },
    {
      country: "Belgium",
      description: `"De Vlaamse Leeuw" (Dutch: [də ˈvlaːmsə ˈleːu]; The Flemish Lion) is the official anthem of Flanders, a region and community in Belgium.`,
      link: "https://en.wikipedia.org/wiki/De_Vlaamse_Leeuw",
      hint: `The official anthem of Flanders, a region and community in its country.`,
    },
    {
      country: "Denmark",
      description: `"The Danish song is a young, blonde girl" (or simply "The Danish song") is a song, originally written as an occasional poem, with lyrics by Kai Hoffmann in 1924 and melody by Carl Nielsen in 1926. It is one of the most popular Danish songs.`,
      link: "https://da.wikipedia.org/wiki/Den_danske_sang_er_en_ung,_blond_pige",
      hint: `A popular song about a young, blonde girl, which comes from this Northern European country.`,
    },
    {
      country: "Netherlands",
      description: `"Aan de Amsterdamse grachten" (Dutch pronunciation: [ˈaːn də ʔɑmstərˌdɑmsə ˈɣrɑxtə(n)]; "At the Amsterdam canals") is a Dutch song by Pieter Goemans. It was written in 1949 but not recorded until 1956, and many times more since then. It is one of the standard songs celebrating the city of Amsterdam, and one of the best-known and most popular songs in the Netherlands.`,
      link: "https://en.wikipedia.org/wiki/Aan_de_Amsterdamse_grachten",
      hint: `A song by Pieter Goemans, written in 1949 and recorded many times since 1956, celebrating a famous feature of the country.`,
    },
    {
      country: "Luxembourg",
      description: `Laurent Menager (1835–1902) was a Luxembourg composer, choirmaster, organist and conductor who is often referred to as Luxembourg's national composer. He founded the national choral association Sang a Klang (1857) and composed many songs, orchestral music and operettas as well as music for brass bands and the theatre. \n When Menager died on 7 February 1902, he had become so popular that he was mourned by the entire nation. Over 6,000 people, including representatives of 45 societies, attended his funeral.`,
      link: "https://en.wikipedia.org/wiki/Laurent_Menager",
      hint: `A composer, choirmaster, organist, and conductor, often referred to as the national composer, who founded the national choral association Sang a Klang.`,
    },
    {
      country: "Hungary",
      description: `Béla Viktor János Bartók (/ˈbeɪlə ˈbɑːrtɒk/; Hungarian: [ˈbeːlɒ ˈbɒrtoːk]; 25 March 1881 – 26 September 1945) was a Hungarian composer, pianist and ethnomusicologist. He is considered one of the most important composers of the 20th century; he and Franz Liszt are regarded as Hungary's greatest composers. Through his collection and analytical study of folk music, he was one of the founders of comparative musicology, which later became known as ethnomusicology.`,
      link: "https://en.wikipedia.org/wiki/B%C3%A9la_Bart%C3%B3k",
      hint: `A composer, pianist, and ethnomusicologist, regarded as one of the most important composers of the 20th century and a founder of comparative musicology.`,
    },
    {
      country: "Slovakia",
      description: `"Na Kráľovej holi" is a Slovak folk song from the Horehronie region. It gained prominence mainly through the film "Zem spieva" (The Earth Sings) made in 1933 by Karol Plicka. In the song's original version, the first stanza mentions the hill leaning towards the land of Šumiac. This altered text was first introduced in the film "The Singing Earth." \n Legend has it that the song's lyrics were written by a soldier from Šumiac fighting on the Russian front, who, after being shot, realized he would probably never return home. \n Another interpretation suggests that the song is the lament of a convict before his execution, with the tree with an inclined top symbolizing the gallows.`,
      link: "https://sk.wikipedia.org/wiki/Na_Kr%C3%A1%C4%BEovej_holi",
      hint: `A folk song from the Horehronie region, gaining prominence through the 1933 film "The Earth Sings," with lyrics about a soldier or convict facing imminent death.`,
    },
    {
      country: "Slovenia",
      description: `Na Golici (German: Trompetenecho) is a Slovenian polka instrumental composed by Slavko Avsenik (1929-2015) and arranged by his brother Vilko Ovsenik (1928-2017, he used an older spelling of the family name). The song was written in 1954 and first recorded in January 1955 in the Radio Kärnten (Carinthia) studio in Klagenfurt, Austria. It is considered the most played instrumental tune in the world. At this time, the band was renamed to Gorenjski kvintet (Original Oberkrainer Quintett). This song has been covered more than 600 times all over the world. \n Golica (German: Kahlkogel), the 1,836 metres (6,024 ft) high Slovenian mountain from which the song takes its name, is a peak in the Western Karawanks, on the border between Slovenia and Austria, above the Slovene town of Jesenice. It is known mainly for its fields of wild white narcissi, swathes of which cover Golica and surrounding pastures in late April and early May. Despite having given the song this title, Slavko Avsenik was never in his life on Golica. Today the band is known as Ansambel bratov Avsenik (Slavko Avsenik und seine Original Oberkrainer), even though the band no longers exists.`,
      link: "https://en.wikipedia.org/wiki/Na_Golici",
      hint: `A polka instrumental composed by Slavko Avsenik, considered the most played instrumental tune in the world, named after a mountain.`,
    },
    {
      country: "Croatia",
      description: `"Lijepa li si" is a single by Marko Perković Thompson from the album Vjetar S Dinare. It is one of Thompson's most famous singles and the most popular Croatian patriotic songs.`,
      link: "https://hr.wikipedia.org/wiki/Lijepa_li_si",
      hint: `A single by Marko Perković Thompson, known as one of his most famous singles and a popular patriotic song.`,
    },
    {
      country: "Serbia",
      description: `Tamo daleko (Serbian Cyrillic: Тамо далеко; "There, Far Away", "Over There, Far Away" or "There, Afar") is a Serbian folk song which was composed in 1916 to commemorate the Serbian Army's retreat through Albania in World War I and during which it was devastated by hunger, disease and attacks by armed bands before regrouping on the Greek island of Corfu, where many more Serbian soldiers died. It revolves around the theme of loss and longing for a distant homeland.`,
      link: "https://en.wikipedia.org/wiki/Tamo_daleko",
      hint: `A folk song composed in 1916 to commemorate the army's retreat through Albania in World War I, revolving around the theme of loss and longing for a distant homeland.`,
    },
    {
      country: "Bosnia and Herzegovina",
      description: `Moj dilbere (English: My Sweetheart or My Darling) is a Bosnian traditional folk and sevdalinka song. \n The song is sung from the female point of view, no matter the gender of the singer.`,
      link: "https://en.wikipedia.org/wiki/Moj_dilbere",
      hint: `A traditional folk and sevdalinka song sung from the female point of view, regardless of the singer's gender.`,
    },
    {
      country: "Montenegro",
      description: `Nenad Knežević (Serbian Cyrillic: Ненад Кнежевић; born 5 December 1967), better known as Knez, is a Montenegrin singer, based in Belgrade, Serbia. Best known for his 1994 hit "Dal' si ikada mene voljela" (Have You Ever Loved Me), he rose to fame as a 1990s dance-pop singer. Knez also represented Montenegro in the Eurovision Song Contest 2015 with "Adio", finishing 13th out of 27 countries, the best result Montenegro has ever achieved at the contest.`,
      link: "https://en.wikipedia.org/wiki/Knez_(singer)",
      hint: `A singer known for his 1994 hit "Dal' si ikada mene voljela" and for representing his country in the Eurovision Song Contest 2015 with "Adio".`,
    },
    {
      country: "North Macedonia",
      description: `Jovano, Jovanke (Bulgarian: Йовано, Йованке; Macedonian: Јовано, Јованке) is a traditional folk song originating from the region of Macedonia. It is popular in and frequently performed in North Macedonia, Bulgaria, and the Macedonia region of Greece. The song has also been adapted and performed in neighboring Balkan states such as Bosnia and Herzegovina, Serbia and Croatia. It is about two young lovers separated by their disapproving parents. The song mentions the Vardar river which runs through present-day North Macedonia and present-day Greece.`,
      link: "https://en.wikipedia.org/wiki/Jovano_Jovanke",
      hint: `A traditional folk song about two young lovers separated by disapproving parents, popular in the region and neighboring Balkan states.`,
    },
    {
      country: "Albania",
      description: `Iso-Polyphony is a traditional part of Albanian folk music and, as such, is included in UNESCO's intangible cultural heritage list.`,
      link: "https://en.wikipedia.org/wiki/Iso-Polyphony",
      hint: `A traditional part of the country's folk music known as Iso-Polyphony, included in UNESCO's intangible cultural heritage list.`,
    },
    {
      country: "Lithuania",
      description: `Sutartinės - multipart songs, (from the Lithuanian word sutarti — to be in concordance, in agreement, singular sutartinė) are unique examples of folk music. They are an ancient form of two and three voiced polyphony, based on the oldest principles of multi-voiced vocal music: heterophony, parallelism, canon and free imitation. Most of the sutartinės were recorded in the 19th and 20th centuries, but sources from the 16th century on show that they were significant along with monophonic songs. Recognizing their uniqueness and value, UNESCO inscribed sutartinės into the representative list of the Masterpieces of the Oral and Intangible Heritage of Humanity in 2010.`,
      link: "https://en.wikipedia.org/wiki/Folk_songs_of_Lithuania",
      hint: `Multipart songs, unique examples of polyphony, inscribed into UNESCO's list of Masterpieces of the Oral and Intangible Heritage of Humanity.`,
    },
    {
      country: "Estonia",
      description: `Arvo Pärt (Estonian pronunciation: [ˈɑrʋo ˈpært]; born 11 September 1935) is an Estonian composer of contemporary classical music. Since the late 1970s, Pärt has worked in a minimalist style that employs tintinnabuli, a compositional technique he invented. Pärt's music is in part inspired by Gregorian chant. His most performed works include Fratres (1977), Spiegel im Spiegel (1978), and Für Alina (1976). From 2011 to 2018, and again in 2022, Pärt was the most performed living composer in the world, and the second most performed in 2019, after John Williams. The Arvo Pärt Centre, in Laulasmaa, was opened to the public in 2018.`,
      link: "https://en.wikipedia.org/wiki/Arvo_P%C3%A4rt",
      hint: `A composer known for his minimalist style and the tintinnabuli technique. From 2011 to 2018, and again in 2022, he was the most performed living composer in the world.`,
    },
    // {
    //   country: "Malta",
    //   description: `The subjects of Maltese folk-songs vary according to circumstances, and this one it is in the nature of a satire upon the faults of the character of the one "That eats too much", hence the Maltese name "Iz-zaqqieq".`,
    //   link: "https://folkways.si.edu/folk-songs-and-music-from-malta/world/album/smithsonian",
    //   hint: `A folk song in the nature of a satire about someone who "eats too much," known by the name "Iz-zaqqieq".`,
    // },
    {
      country: "Cyprus",
      description: `The music of Cyprus includes a variety of traditional, Western classical and Western popular genres. Cypriot traditional music is similar to the traditional music of Greece with Turkish and Arab influences, and includes dances like sousta, syrtos, ballos, tatsia, antikristos, arabiye, karotseris, sinalik, chiftetteli, zeimbekiko and the mandra dance.`,
      link: "https://en.wikipedia.org/wiki/Music_of_Cyprus",
      hint: `The music includes a variety of traditional genres similar to Greek music with Turkish and Arab influences, featuring dances like sousta, syrtos, and ballos.`,
    },
    {
      country: "Armenia",
      description: `"Dle Yaman" (in Armenian Դլե Յաման, in Western Armenian Տըլէ Եաման) also francicized "Délé Yaman" is an Armenian traditional tune. It is the story of a tragic love affair between two persons. It was discovered by Father Komitas in his travels in Armenian countryside. He re-arranged the lyrics and the music for the piano. "Dle Yaman" is very often interpreted with solo bits made with the duduk.`,
      link: "https://en.wikipedia.org/wiki/Dle_Yaman",
      hint: `A traditional tune about a tragic love affair, discovered and arranged by Father Komitas, often featuring the duduk.`,
    },
    {
      country: "Azerbaijan",
      description: `"Song of the Blind Arab" (Azerbaijani: Kor ərəbin mahnısı) is a song written by the Azerbaijani composer Fikret Amirov for the staging of the "Sheikh Sanan" play by Huseyn Javid. It is noted that the song is a typical example of a ballad song.`,
      link: "https://en.wikipedia.org/wiki/Song_of_the_Blind_Arab",
      hint: `A song written by composer Fikret Amirov for the play "Sheikh Sanan," typical of a ballad song.`,
    },
    {
      country: "Georgia",
      description: `"Chakrulo" (Georgian: ჩაკრულო, transliterated: chak'rulo) is a Georgian polyphonic choral folk song. It is a three-part song from the region of Kakheti, dramatising preparations for a battle. It is characterised by two highly ornamented individual vocal parts over a choral foundation. \n When Georgian vocal polyphony was recognized by UNESCO, as an Intangible Heritage masterpiece in 2001, Chakrulo was cited as a prime example. Chakrulo was one of 29 musical compositions included on the Voyager Golden Records that were sent into space on Voyager 2 on 20 August 1977, and Voyager 1 on 5 September 1977.`,
      link: "https://en.wikipedia.org/wiki/Chakrulo",
      hint: `A polyphonic choral folk song from the region of Kakheti, dramatising preparations for battle, cited by UNESCO as a prime example of vocal polyphony.`,
    },
    {
      country: "Uzbekistan",
      description: `Qaradali, a piece that performers link to the Khorezm dutar maqam, embodies a well-known legend. Qaradali was the name of a favorite horse of the Khorezm khan. The horse grew old and sick, and the khan called for help. He warned, however, that anyone who told him that the horse would die would have his head cut off. Fearful of punishment, no one came forward to explain to the khan the true fate of his horse. Finally, a lone dutar player appeared, and, saying nothing, picked up his dutar and played this piece. The khan understood the meaning of the piece, and wept. `,
      link: "https://folkways.si.edu/uzbekistan-music-of-khorezm/world/music/album/smithsonian",
      hint: `A piece linked to the Khorezm dutar maqam, telling the legend of the khan's favorite horse and a dutar player who conveyed its fate through music.`,
    },
    {
      country: "Turkmenistan",
      description: `The music of the nomadic and rural Turkmen people is closely related to Kyrgyz and Kazakh folk forms. Important musical traditions in Turkmen music include traveling singers and shamans called bakshy, who act as healers and magicians and sing either a cappella or with instruments such as the two-stringed lute called dutar.`,
      link: "https://en.wikipedia.org/wiki/Music_of_Turkmenistan",
      hint: `Music related to Kyrgyz and Kazakh folk forms, featuring traveling singers and shamans called bakshy, who use a two-stringed lute called dutar.`,
    },
    {
      country: "Kyrgyzstan",
      description: `Throughout Central Asia, legends portray Kambarkan, also known as Kambar-ata, as the father of music, inventor of musical instruments, and patron of musicians. Among Kyrgyz musicians, he is regarded as the creator of the komuz. Nurak recounts the creation legend as follows: "Kambarkan was a hunter, and once when he went to the forest, a monkey fell as he jumped from one tree to another. The monkey's stomach became impaled on a sharp twig and burst open, and his intestine got stretched out between a high tree branch and the ground. When it dried, a wind came up, and the intestine emitted a magical sound. Kambarkan realized that the sound was coming from the monkey's intestine, and he got the idea of using that intestine to make an instrument. He took a piece of wood, curved it, and fixed the intestine tightly over it. That's how he invented the komuz." No evidence suggests that monkeys ever lived in what is now Kyrgyzstan, but terra-cotta statues of monkey- musicians dating back almost two thousand years have been unearthed in various parts of Central Asia. A variant of the legend recounted by Nurak was also known in India, where it turns up in 14th-century literary sources, underscoring historical links between Central Asia and the Subcontinent.`,
      link: "https://folkways.si.edu/music-of-central-asia-vol-1-tengir-too-mountain-from-kyrgyzstan/islamica-world/album/smithsonian",
      hint: `Legendary father of music and inventor of the komuz, with a creation story involving a monkey's intestine and the discovery of a musical sound.`,
    },
    {
      country: "Tajikistan",
      description: `Tajik music is closely related to other Central Asian forms of music. The classical music is shashmaqam, which is also distinctive in Uzbekistan. Southern Tajikistan has a distinctive form of folk music called falak, which is played at celebrations for weddings, circumcisions and other occasions.`,
      link: "https://en.wikipedia.org/wiki/Music_of_Tajikistan",
      hint: `Music related to other Central Asian forms, featuring classical shashmaqam and a distinctive form of folk music called falak.`,
    },
    {
      country: "Saudi Arabia",
      description: `Mohammed Abdu (Arabic: محمد عبده; born June 12, 1949) is a Saudi singer who is renowned across the Middle East and Arab world. He has been described as "Artist of the Arabs."`,
      link: "https://en.wikipedia.org/wiki/Mohammed_Abdu",
      hint: `A renowned singer across the Middle East and Arab world, described as "Artist of the Arabs."`,
    },
    {
      country: "United Arab Emirates",
      description: `Mehad Hamad Mehad (Arabic: ميحد حمد), is an Emirati artist and singer. \n Hamad mainly interprets traditional Emirati songs, often accomplished by an oud. He is well known in the United Arab Emirates for his songs about the desert and his patriotic songs.`,
      link: "https://en.wikipedia.org/wiki/Mehad_Hamad",
      hint: `An artist and singer known for interpreting traditional songs with an oud, famous for his songs about the desert and patriotic themes.`,
    },
    {
      country: "Qatar",
      description: `Fahad Al Kubaisi (Arabic: فهد الكبيسي; born April 12, 1981) is a Qatari singer, record producer, human rights activist, and fashion model. His voice has been described as a "tender" baritone. He became successful in Arab states of the Persian Gulf and Maghreb. He is also famous for producing numerous genres of Khaliji music, which were well known throughout the Persian Gulf countries.`,
      link: "https://en.wikipedia.org/wiki/Fahad_Al_Kubaisi",
      hint: `A singer, record producer, and human rights activist known for his "tender" baritone voice and producing numerous genres of Khaliji music.`,
    },
    {
      country: "Bahrain",
      description: `The island nation of Bahrain, literally meaning “two seas,” sits along the western edge of the Persian Gulf. Prior to the discovery of oil there in 1932, ninety percent of the country’s population worked in jobs relating to the sea: as shipbuilders, sailors, fishermen, and pearl divers. The pearl divers are known for practicing what is considered the Bahrain’s most original and characteristic musical genre, fidjeri, or “sea music.” These songs, consisting of an all-male chorus and solo singer with minimal percussion, often accompanied specific and common actions, like rowing, setting sails, and pulling up the anchor. The liner notes, written by Habib Hassan Touma (d. 1998), a Palestinian composer, ethnomusicologist, and Arabian music expert, include the mythological origin story of fidjeri and further musical context.`,
      link: "https://folkways.si.edu/bahrain-fidjeri-songs-of-the-pearl-divers/world/music/album/smithsonian",
      hint: `This Middle Eastern island nation is known for its "sea music" genre, practiced by pearl divers and characterized by an all-male chorus and solo singer.`,
    },
    {
      country: "Uganda",
      description: `Paulo Kafeero born Paul Job Kafeero and musically or commonly known as Prince Job Paulo Kafeero(Golden boy of Africa)(12 July 1970 - 17 May 2007) was a Ugandan Afro-folk singer. He is regarded as one of the pioneers of the local Ugandan Genre Kadongo Kamu, Kafeero won the Pearl of Africa Music Awards for best Kadongo Kamu artist/group.`,
      link: "https://en.wikipedia.org/wiki/Paulo_Kafeero",
      hint: `An Afro-folk singer known as the "Golden boy of Africa," regarded as a pioneer of the local genre Kadongo Kamu.`,
    },
    {
      country: "Algeria",
      description: `Khaled Hadj Ibrahim (Arabic: خالد حاج إبرهيم, [ˈxaːled ħaːd͡ʒ ɪbˈrahiːm]; born 29 February 1960), better known by his mononym Khaled (Arabic: خالد), is an Algerian raï singer, musician and songwriter. He began recording in his early teens under the name Cheb Khaled (شاب خالد, Arabic for "Young" Khaled, with "Cheb" as a common title for male raï singers). \n Khaled is one of the most important musicians in the history of Raï music in his native Algeria and is one the world's best-known Arab singers. To date, Khaled has sold over 80.5 million albums (10 diamond, platinum, and gold) worldwide, making him one of the bestselling Arabic-language singers in history. Among his most famous songs are "Aïcha", "Didi", "El Arbi", "Abdel Kader", "La Poupée qui fait non", "Wahran Wahran", "Bakhta", "C'est la vie", and "Alech Taadi". \n He holds the Guinness World Record for best-selling artist of raï music.`,
      link: "https://en.wikipedia.org/wiki/Khaled_(musician)",
      hint: `A raï singer, musician, and songwriter, one of the best-known Arab singers, holding the Guinness World Record for best-selling artist of raï music.`,
    },
    {
      country: "Sudan",
      description: `Mohammed Osman Hassan Salih Wardi (Arabic: محمد عثمان حسن وردي; 19 July 1932 – 18 February 2012), also known as Mohammed Wardi, was a Nubian Sudanese singer, poet and songwriter. Looking back at his life and artistic career, Sudanese writer and critic Lemya Shammat called him an "inspirational figure in Sudanese music and culture, whose prolific talent and massive contribution remains unsurpassed in Sudan."`,
      link: "https://en.wikipedia.org/wiki/Mohammed_Wardi",
      hint: `A Nubian singer, poet, and songwriter, regarded as an inspirational figure in the country's music and culture.`,
    },
    {
      country: "Angola",
      description: `In 1975, the Popular Movement for the Liberation of Angola (MPLA) gained control of the People’s Republic of Angola, ending more than a decade of struggle against the powerful Portuguese colonial forces and armies aided by South Africa and the United States. The songs on this album, sung in Portuguese and local languages Kimbundu and Umbundu, were recorded soon after the end of colonial rule, and the lyrics convey messages of struggle, victory, and hope. The liner notes include lyrics in English and an essay first published in 1978 in the Guardian, discussing Angola as “the focal point of confrontation...between socialism and capitalism.”`,
      link: "https://folkways.si.edu/angola-forward-peoples-power/historical-song-struggle-protest-world/music/album/smithsonian",
      hint: `Songs recorded after the end of colonial rule, conveying messages of struggle, victory, and hope, sung in Portuguese and local languages Kimbundu and Umbundu.`,
    },
    {
      country: "Niger",
      description: `Etran Finatawa is a Niger-based band, formed in 2004 during the Festival au Désert near Timbuktu, Mali. The music of Etran Finatawa blends the traditional music of the Wodaabe and Tuareg people with western instruments such as the electric guitar.`,
      link: "https://en.wikipedia.org/wiki/Etran_Finatawa",
      hint: `A band formed in 2004 during the Festival au Désert, blending the traditional music of the Wodaabe and Tuareg people with western instruments like the electric guitar.`,
    },
    {
      country: "Burkina Faso",
      description: `Amadou Traoré (March 8, 1944 – August 27, 2014), better known as Amadou Balaké, was a popular singer from Burkina Faso. During his career, which lasted over 50 years, Balaké recorded and performed in different parts of West Africa as well as New York and Paris. In the early 21st century, he was a member of Senegalese salsa band Africando. His music combined different traditions from his home country, primarily Dioula (Mandé) and Mossi, as well as Afro-Cuban music.`,
      link: "https://en.wikipedia.org/wiki/Amadou_Balak%C3%A9",
      hint: `A popular singer whose career spanned over 50 years, combining Dioula (Mandé), Mossi, and Afro-Cuban music, and a member of the Senegalese salsa band Africando.`,
    },
    {
      country: "Mali",
      description: `Ali Ibrahim "Ali Farka" Touré (31 October 1939 – 6 March 2006) was a singer and multi-instrumentalist, and one of the African continent's most internationally renowned musicians. His music blends traditional local music and its derivative, African American blues and is considered a pioneer of African desert blues. Touré was ranked number 76 on Rolling Stone's list of "The 100 Greatest Guitarists of All Time" and number 37 on Spin magazine's "100 Greatest Guitarists of All Time".`,
      link: "https://en.wikipedia.org/wiki/Ali_Farka_Tour%C3%A9",
      hint: `A singer and multi-instrumentalist, renowned for blending traditional local music with African American blues, considered a pioneer of African desert blues.`,
    },
    {
      country: "Finland",
      description: `"Ievan polkka" (Finnish for "Ieva's Polka") is a Finnish song with lyrics printed in 1928 and written by Eino Kettunen to a traditional Finnish polka tune. The song is sung in an Eastern Savonian dialect spoken in North Karelia. It is sung from the point of view of a young man, about a woman called Ieva (dialectal for the name Eva or Eeva in standard Finnish) who sneaks out and dances the polka with him all night. The song is often mistaken for a traditional folk song, but the lyrics by Eino Kettunen are still under copyright. Later, a scat singing version of the song by the band Loituma was incorporated into the viral animation Loituma Girl. In 2007, the song was brought into worldwide popular culture through a cover sung by Hatsune Miku, with Otomania arranging the music and providing Miku's voice manipulation.`,
      link: "https://en.wikipedia.org/wiki/Ievan_polkka",
      hint: `A song written in 1928 by Eino Kettunen, often mistaken for a traditional folk song, featuring a woman sneaking out to dance the polka all night.`,
    },
    {
      country: "Pakistan",
      description: `This type of Punjabi folk song is sung by groups of women or girls, to the accompaniment of a dholak drum. These songs depict the romantic side of rural life, and are sung on festive occasions such as marriages, harvest festivals, and village fairs. The instruments accompanying the voices in this piece are the dholak, a clarinet, tabla drums. The performance is by Munawar Sultana, Iqbal Banso and group.`,
      link: "https://folkways.si.edu/folk-music-of-pakistan/central-asia-islamica-world/album/smithsonian",
      hint: `Punjabi folk songs sung by groups of women or girls, accompanied by a dholak drum, depicting the romantic side of rural life and performed on festive occasions.`,
    },
    {
      country: "Bangladesh",
      description: `Firoza Begum (Bengali: ফিরোজা বেগম; 28 July 1930 – 9 September 2014) was a Bangladeshi Nazrul Geeti singer. She was awarded the Independence Day Award in 1979 by the Government of Bangladesh.`,
      link: "https://en.wikipedia.org/wiki/Firoza_Begum_(singer)",
      hint: `A renowned Nazrul Geeti singer awarded the Independence Day Award in 1979 by her country's government.`,
    },
    {
      country: "Syria",
      description: `Sabah al-Din Abu Qaws (Arabic: صباح الدين أبو قوس), also known as Sabah Fakhri (Arabic: صباح فخري North Levantine Arabic pronunciation: [sˤɑbɑːħ fæxɾiː]; May 2, 1933 – November 2, 2021), was a Syrian tenor singer from Aleppo. \n With over 50 years of fame and popularity as a singer, Sabah Fakhri modified and popularized the then-fading forms of traditional Arabic music Muwashahat and Qudud Halabiya. He was well known for his beautiful strong vocals, impeccable control and execution of Maqamat and harmony, as well as his dominant and charismatic performances on stage. In the Arab world, he is regarded as one of the greatest artists of all time.`,
      link: "https://en.wikipedia.org/wiki/Sabah_Fakhri",
      hint: `A tenor singer who modified and popularized traditional Arabic music forms, known for his strong vocals and charismatic stage performances.`,
    },
    {
      country: "Italy",
      description: `Luciano Pavarotti was an Italian operatic tenor who during the late part of his career crossed over into popular music, eventually becoming one of the most acclaimed tenors of all time. He made numerous recordings of complete operas and individual arias, gaining worldwide fame for his tone, and gaining the nickname "King of the High Cs".`,
      link: "https://en.wikipedia.org/wiki/Luciano_Pavarotti",
      hint: `An operatic tenor who crossed over into popular music, known as one of the most acclaimed tenors of all time and nicknamed "King of the High Cs".`,
    },
    //
    {
      country: "Yemen",
      description: `Ayoob Tarish Absi (Arabic: أيوب طارش عبسي, born 1942; first name also spelled Ayoub; last name also spelled Absey) is a Yemeni singer and musician from the area of Al-Aboos in the Ta'izz Governorate. Tarish composed "United Republic", the national anthem of Yemen, which was adopted following the Yemenite reunification.`,
      link: "https://en.wikipedia.org/wiki/Ayoob_Tarish",
      hint: `A singer and musician who composed the national anthem adopted following reunification.`,
    },
    {
      country: "Jordan",
      description: `Omar Al-Abdallat (Arabic: عمر العبداللات; transliterated: "Umar al-"Abdallāt) is a Jordanian singer-songwriter credited with popularizing Bedouin music. He has produced and/or performed a number of Jordanian patriotic standards, including "Hashimi, Hashimi" and "Jeishana" in addition to traditional Jordanian songs. He has also represented Jordan in multicultural events throughout the world. He is also popular in various cities of other Arab countries.`,
      link: "https://en.wikipedia.org/wiki/Omar_Al-Abdallat",
      hint: `A singer-songwriter credited with popularizing Bedouin music and known for producing and performing patriotic standards.`,
    },
    {
      country: "Venezuela",
      description: `"Caballo Viejo" ('Old Horse') is a Venezuelan folk song written and composed by Simón Díaz, which appears on the 1980 album Golpe Y Pasaje. It has become one of the most important folk songs in Venezuela and is regarded as a classic.`,
      link: "https://en.wikipedia.org/wiki/Caballo_Viejo",
      hint: `A folk song written and composed by Simón Díaz, regarded as a classic and one of the most important folk songs in its country.`,
    },
    {
      country: "Tunisia",
      description: `Emel Mathlouthi (Arabic: آمال المثلوثي) also known as Emel, born 11 January 1982), is a Tunisian-American singer-songwriter, musician, arranger and producer. She rose to fame with her protest song "Kelmti Horra" ("My Word is Free"), which became an anthem for the Tunisian Revolution and the Arab Spring.`,
      link: "https://en.wikipedia.org/wiki/Emel_Mathlouthi",
      hint: `A singer-songwriter known for her protest song "Kelmti Horra" ("My Word is Free"), which became an anthem for a revolution and the Arab Spring.`,
    },
    // {
    //   country: "Lebanon",
    //   description: `Nouhad Wadie Haddad (Arabic: نهاد وديع حداد, romanized: Nuhād Wadīʿ Ḥaddād, Lebanese Arabic pronunciation: [nʊˈhaːd waˈdiːʕ ħadˈdaːd]; born November 21, 1934), known as Fairuz (Arabic: فيروز, romanized: Fayrūz, pronounced [fajˈruːz]), is a Lebanese singer. She is widely considered an iconic vocalist and one of the most celebrated singers in the history of the Arab world. She is popularly known as "The Bird Of The East," "The Cedar Of Lebanon," "The Moon's Neighbor", and “The Voice Of Lebanon”, among others.`,
    //   link: "https://en.wikipedia.org/wiki/Fairuz",
    // },
    {
      country: "Haiti",
      description: `Michel Joseph Martelly (French pronunciation: [miʃɛl ʒozɛf maʁtɛli]; born 12 February 1961) is a Haitian musician and politician who was the President of Haiti from May 2011 until February 2016. \n Martelly was one of Haiti's best-known musicians for over a decade, going by the stage name Sweet Micky. For business and musical reasons, Martelly has moved a number of times between the United States and Haiti. When travelling to the United States, Martelly mostly stays in Florida. After his presidency, Martelly returned to his former band and sang a carnival méringue entitled "Bal Bannann nan" (Give Her the Banana), as a mocking response to Liliane Pierre Paul, a famous Haitian female journalist in Port-au-Prince.`,
      link: "https://en.wikipedia.org/wiki/Michel_Martelly",
      hint: `A musician known by the stage name Sweet Micky, who later became the president and returned to his band after his presidency.`,
    },
    {
      country: "Benin",
      description: `Gnonnan Sossou Pierre Kouassivi, known by the stage name Gnonnas Pedro was a singer and musician from Lokossa, Benin. He is perhaps best known as the lead singer of Africando between 1995 and his death in 2004 but had been well known in his home country of Benin and beyond since the 1960s.`,
      link: "https://en.wikipedia.org/wiki/Gnonnas_Pedro",
      hint: `A singer and musician best known as the lead singer of Africando, with a career spanning from the 1960s until his death in 2004.`,
    },
    {
      country: "Eritrea",
      description: `Yemane Ghebremichael (born January 21, 1949 – November 5, 1997) (commonly known as Yemane Baria or Yemane Barya) was a well-known Eritrean songwriter, composer and singer. He became one of the most renowned Eritrean artists (a Tigrinya singer).`,
      link: "https://en.wikipedia.org/wiki/Yemane_Ghebremichael",
      hint: `A well-known songwriter, composer, and singer, renowned for his Tigrinya songs.`,
    },
    {
      country: "Sierra Leone",
      description: `Sooliman Ernest Rogers "Rogie", (1926 – 4 July 1994) better known as S. E. Rogie, was a highlife and palm wine musician and guitarist.`,
      link: "https://en.wikipedia.org/wiki/S._E._Rogie",
      hint: `A highlife and palm wine musician and guitarist.`,
    },
    {
      country: "Zambia",
      description: `Alick Nkhata (1922–1978) was a Zambian musician, freedom fighter and broadcaster from the 1950s to the mid-1970s. He was also the director of the Zambia National Broadcasting Corporation (ZNBC), and formed the Lusaka Radio Band, later called the Big Gold Six Band. The band played Zambian music and scored translations of original rural songs.`,
      link: "https://en.wikipedia.org/wiki/Alick_Nkhata",
      hint: `A musician, freedom fighter, and broadcaster who formed a band that played music and translated original rural songs.`,
    },
    {
      country: "South Sudan",
      description: `Gordon Koang is a blind South Sudanese musician based in Australia. He is known in South Sudan as the country's "King of Music".`,
      link: "https://en.wikipedia.org/wiki/Gordon_Koang",
      hint: `A blind musician known as the "King of Music" in his country.`,
    },
    {
      country: "Chad",
      description: `The balafon is a xylophone found throughout the Chad, particularly in the regions inhabited by the Sara tribes. The dance played here, called the "Saye" is one of the most widespread. Like all dances of the Sara, it is well liked by young people, calling for great physical exertion, its execution demanding great bodily suppleness. \n The song heard here, executed by a group of balafon players and accompanied by a battery of drums, tells about the legendary" charity of the head of a family whose land is visited by all and sundry."`,
      link: "https://folkways.si.edu/music-of-chad/islamica-world/album/smithsonian",
      hint: `A xylophone found in regions inhabited by the Sara tribes, known for dances requiring great physical exertion and suppleness.`,
    },
    {
      country: "Guyana",
      description: `Terry Vivekanand Gajraj is a Guyanese chutney and chutney-soca artist.`,
      link: "https://en.wikipedia.org/wiki/Terry_Gajraj",
      hint: `A chutney and chutney-soca artist.`,
    },
    {
      country: "Guinea",
      description: `Djeli Moussa Diawara, born 1962 in Kankan, Guinea, is a Kora player (Korafola), composer and singer.`,
      link: "https://en.wikipedia.org/wiki/Djeli_Moussa_Diawara",
      hint: `A Kora player, composer, and singer born in 1962 in Kankan.`,
    },
    {
      country: "Liberia",
      description: `This triangular framed zither song, according to soloist Flumo of Totota, depicts a leopard hunting for food in the rain forest. Melodic motives interlock in hocket style. The player holds the instrument at a right angle to his body, pressing the gourd resonator against his chest. The koniŋ has 9 strings (in this case) stretched across a triangular wooden frame that is attached to a gourd resonator. Of the nine wire strings, the five highest pitched are nônii (children) and the four lowest pitched are ǹeeŋa (mothers), as explained by the soloist.`,
      link: "https://folkways.si.edu/music-of-the-kpelle-of-liberia/world/album/smithsonian",
      hint: `A song depicting a leopard hunting, played on a triangular framed zither with 9 strings.`,
    },
    {
      country: "Mauritania",
      description: `Dimi Mint Abba (Arabic: ديمي منت آبا‎; 25 December 1958 – June 2011) was one of Mauritania's most famous musicians. She was born Loula Bint Siddaty Ould Abba in Tidjikja in Mauritania. 1958, into a low-caste ("iggawin") family specializing in the griot tradition.`,
      link: "https://en.wikipedia.org/wiki/Dimi_Mint_Abba",
      hint: `One of the most famous musicians born into a low-caste family specializing in the griot tradition.`,
    },
    // {
    //   country: "Malawi",
    //   description: `Gidesi Chalamanda was born on 15 January 1931, is most commonly known as "Agide". He is a Malawian acoustic artist. He was born in Chiradzulu in the Southern region of Malawi. \n He is one of Malawi's legendary artists. His song "linny" a mashup with Dr Namadingo trended on TikTok gaining over a million views and challenges across the world.`,
    //   link: "https://en.wikipedia.org/wiki/Giddess_Chalamanda",
    //   hint: `A legendary acoustic artist whose song "Linny" trended on TikTok.`,
    // },
    // {
    //   country: "Namibia",
    //   description: `Jackson Kaujeua (3 July 1953 – 27 May 2010) was a Namibian musician, composer and gospel singer, and a veteran of the Namibian struggle for independence. He sang in various Namibian languages but also Afrikaans and English.`,
    //   link: "https://en.wikipedia.org/wiki/Jackson_Kaujeua",
    // },
    {
      country: "The Gambia",
      description: `Jaliba Kuyateh is a Gambian musician. He is well known internationally as the "King of Kora." His music mixes traditional kora beats with modern pop music and is often referred to as "kora pop." Kuyateh is a former school teacher, a resident of Brikama, and performs extensively abroad.`,
      link: "https://en.wikipedia.org/wiki/Jaliba_Kuyateh",
      hint: `A musician known as the "King of Kora," mixing traditional beats with modern pop music.`,
    },
    {
      country: "Burundi",
      description: `The Royal Drummers of Burundi, commonly known in recordings as The Drummers of Burundi or as The Master Drummers of Burundi, is a percussion ensemble originally from Burundi. Their performances are a part of ceremonies such as births, funerals, and coronations of mwami (Kings). Drums (called karyenda) are sacred in Burundi, and represent the mwami, fertility and regeneration. The Royal Drummers use drums made from hollowed tree trunks covered with animal skins. In addition to the central drum, called Inkiranya, there are Amashako drums which provide a continuous beat, and Ibishikiso drums, which follow the rhythm established by the Inkiranya.`,
      link: "https://en.wikipedia.org/wiki/Royal_Drummers_of_Burundi",
      hint: `A percussion ensemble known for their sacred drums used in ceremonies.`,
    },
    {
      country: "Lesotho",
      description: `Music of Lesotho is a compilation by Bill Wood from recordings made between 1972 and 1975, when Wood was teaching at a secondary school in the Mafeteng District of Lesotho. The music in this recording excludes religious and choral music in favor of the traditional repertoire of the Basotho people. Wood observes that, while traditional culture is very strong, “new songs in traditional style are being composed all the time.” The male students at the school where Wood taught perform, and selections cover a range of styles and subjects (initiation, dance and game songs, improvised poetry, and more). Liner notes include lyrics in Sesotho (one of Lesotho’s two official languages), English translations, photos, information about Lesotho’s history and culture, as well as Wood’s personal reflections on his experience recording the album.`,
      link: "https://folkways.si.edu/music-of-lesotho/world/album/smithsonian",
      hint: `A compilation of traditional music from recordings made by a teacher in the 1970s.`,
    },
    // {
    //   country: "Oman",
    //   description: `Haitham Rafi (Arabic: هيثم رافي) is an Omani singer who gained fame after winning a popular Indian reality show. He is the first Omani artist to compete and challenge Indian singers, and is considered the number one Omani artist in the music industry.`,
    //   link: "https://en.wikipedia.org/wiki/Haitham_Mohammed_Rafi",
    //   hint: `A singer known for his vocal talents, considered the top artist in this Arab country.`,
    // },
    {
      country: "Bhutan",
      description: `The soundscapes collected in Music of Bhutan offer invaluable documentation and serve as a pillar by which the success of Bhutan's cultural preservation can be measured. Anthropologist and early Himalayan traveler, Dr. Johann S. Szuszkiewicz, produced Music of Bhutan in 1978, a time when scholars had just begun to study Bhutanese music, in an era when many Western recordings of the Himalayas focused only on Buddhist liturgical music. Despite inherent political, developmental, and demographic changes throughout the years, the very songs, instrumental music, dances, and spoken word compiled in Music of Bhutan are often still heard today.`,
      link: "https://folkways.si.edu/music-of-bhutan/world/album/smithsonian",
      hint: `A collection of soundscapes documenting traditional music and cultural preservation in a Himalayan country.`,
    },
    {
      country: "Fiji",
      description: `From island villages of Fiji in the Melanesian South Pacific, the songs known as sere ni cumu are music of a special time and place. These 1986 recordings represent social songs that brought life and togetherness to the beer- or kava-drinking gatherings of the 1920s and beyond. Drawing from pre-European texts and music styles, as well as European melodies and harmonies and ukulele and guitar accompaniment, they mark the sound of Fiji village life of their era.`,
      link: "https://folkways.si.edu/fiji-songs-of-love-and-homeland-string-band-music/world/music/album/smithsonian",
      hint: `Social songs that brought life and togetherness to beer- or kava-drinking gatherings.`,
    },
    {
      country: "Suriname",
      description: `Julius Theodoor Hugo Uiterloo (December 13, 1934 – November 15, 1975), better known by his stage-name Lieve Hugo and his nickname Iko, was a Surinamese singer. He was one of the pioneers of the kaseko-genre; hence his other nickname King of Kaseko.`,
      link: "https://en.wikipedia.org/wiki/Lieve_Hugo",
      hint: `A singer known as the King of Kaseko.`,
    },
    // {
    //   country: "Seychelles",
    //   description: `Jean-Marc Volcy (born January 29, 1966) is a Seychellois composer, performer and songwriter regarded as one of the pioneering musicians to propagate creole music in Seychelles.`,
    //   link: "https://en.wikipedia.org/wiki/Jean-Marc_Volcy",
    //   hint: `A composer and performer pioneering creole music in his country.`,
    // },
    {
      country: "Cabo Verde",
      description: `Cesária Évora GCIH (Portuguese pronunciation: [sɨˈzaɾiɐ ˈɛvuɾɐ]; 27 August 1941 – 17 December 2011) was a Cape Verdean singer known for singing morna, a genre of music from Cape Verde, in her native Cape Verdean Creole. Her songs were often devoted to themes of love, homesickness, nostalgia, and the history of the Cape Verdean people.`,
      link: "https://en.wikipedia.org/wiki/Ces%C3%A1ria_%C3%89vora",
      hint: `A singer known for performing morna, a genre from her country.`,
    },
    // {
    //   country: "Eswatini",
    //   description: `"Mine Bengidzakiwe" is a traditional song sung in native ceremonies in Swaziland, which became a local hit in 2007.`,
    //   link: "https://en.wikipedia.org/wiki/Mine_bengidzakiwe",
    //   hint: `A traditional song sung in native ceremonies.`,
    // },
    // {
    //   country: "Samoa",
    //   description: `Laulau Siva, by all the matai of Solosolo, Upolu. A laulau siua is a short song which introduces an evening of dancing and singing. The singers are accompanied by a rolled-mat player.`,
    //   link: "https://folkways.si.edu/music-from-western-samoa-from-conch-shell-to-disco/world/album/smithsonian",
    //   hint: `A short song introducing an evening of dancing and singing, accompanied by a rolled-mat player.`,
    // },
    {
      country: "Uruguay",
      description: `"A Don José" is a song composed by maestro Rubén Lena in honor of the oriental hero José Artigas. It was registered in the National Library on May 26, 1964, and composed in 1961 for a songbook that Rubén Lena devised for the children of the school where he served as head teacher. When he composed the song, Rubén Lena was not yet known as a composer and poet; he worked as a rural teacher in Isla Patrulla, a town in the department of Treinta y Tres. The song arose from Rubén Lena's desire to find a composition referring to Artigas that was not as distant as those that had existed until then, and that, with simplicity, was close to the people. For this, he gave it lyrical simplicity and composed it in the form of a milonga, considering it the most traditional rhythm. It was popularized by the group Los Olimareños. In 2003 it was declared a Uruguayan cultural and popular anthem by law. The song is part of the Uruguayan identity and is sung in public schools, mainly on June 19, the anniversary of the birth of José Gervasio Artigas and promise and swearing of the flag in Uruguay. It is found in the repertoire of the National Army of Uruguay.`,
      link: "https://es.wikipedia.org/wiki/A_don_Jos%C3%A9",
      hint: `A song composed in honor of a national hero, now a cultural and popular anthem.`,
    },
    {
      country: "Moldova",
      description: `Ion Paladi (N. October 10 1984, Chişcăreni, Singerei) is an interpreter of folk music from Republic of Moldova.`,
      link: "https://ro.wikipedia.org/wiki/Ion_Paladi",
      hint: `A folk music interpreter from this country.`,
    },
    {
      country: "Laos",
      description: `Solo for a six-foot khene played by Thao Phet Sananikhone. \n This is an example of music for the six-foot khene played by a virtuoso of the instrument who performs three songs of the South in the finest tradition. Because of its dimensions, this khilne calls for a player with powerful lungs, and therefore good players of the six-foot khene are rare.`,
      link: "https://folkways.si.edu/laos-traditional-music-of-the-south/world/music/album/smithsonian",
      hint: `Music for a six-foot khene played by a virtuoso, performing traditional songs.`,
    },
    {
      country: "Papua New Guinea",
      description: `Two generations of Bosavi musicians and 25 years of field recordings: a comprehensive and intimate musical portrait of life in a Papua New Guinea rainforest community. Includes the contemporary music of the youngest generation of guitar band composers, and performances of many traditional everyday and ritual styles featuring their parents.`,
      link: "https://folkways.si.edu/bosavi-rainforest-music-from-papua-new-guinea/world/album/smithsonian",
      hint: `A musical portrait of life in a rainforest community, featuring contemporary and traditional styles.`,
    },
    {
      country: "Guatemala",
      description: `"Luna de Xelajú" is a popular Guatemalan waltz composed by Paco Pérez in 1944. \n Despite the specific context in which the song was written, it is sometimes referred to as Guatemala's second national anthem.`,
      link: "https://en.wikipedia.org/wiki/Luna_de_Xelaj%C3%BA",
      hint: `A popular waltz sometimes referred to as this country's second national anthem.`,
    },
    {
      country: "Belize",
      description: `Andy Vivian Palacio (December 2, 1960 – January 19, 2008) was a Belizean punta musician and government official. He was also a leading activist for the Garifuna people and their culture.`,
      link: "https://en.wikipedia.org/wiki/Andy_Palacio",
      hint: `A punta musician and leading activist for the Garifuna people and their culture.`,
    },
    {
      country: "Nicaragua",
      description: `Carlos Mejía Godoy (born June 27, 1943) is a Nicaraguan musician, composer and singer-songwriter and one of the main representatives of the testimonial song or new song of his country.`,
      link: "https://en.wikipedia.org/wiki/Carlos_Mej%C3%ADa_Godoy",
      hint: `A musician, composer, and singer-songwriter representing the testimonial song movement in his country.`,
    },
    {
      country: "Costa Rica",
      description: `Carlos Guzmán Bermúdez (February 20, 1957 in Desamparados , San José , Costa Rica ) is a musician, composer, producer, arranger and musical director. With a long career in Costa Rican popular music, he has been the winner of several awards, including the most important the Aquileo Echeverría National Music Award in 2008. \n The album 'Gaviota de Fiesta' includes his hit 'Acércate', the official song of the Pyrenees World Festival in France in 2000, and the most notable song composed by Carlos Guzmán called: Soy Tico. Which is part of the Costa Rican national songbook and has become an endearing anthem and song for many 'Ticos' who live outside the country and without a doubt an indisputable success for Gaviota.`,
      link: "https://es.wikipedia.org/wiki/Carlos_Guzm%C3%A1n_Berm%C3%BAdez",
      hint: `A musician, composer, and producer known for his hit 'Acércate' and the song 'Soy Tico'.`,
    },
    {
      country: "Panama",
      description: `Edgardo Armando Franco (born 27 September 1969), better known as El General, is a Panamanian former reggae artist considered by some to be one of the fathers of reggae en Español and a precursor to reggaetón.`,
      link: "https://en.wikipedia.org/wiki/El_General",
      hint: `A former reggae artist considered a father of reggae en Español and a precursor to reggaetón.`,
    },
    // {
    //   country: "Gabon",
    //   description: `Patience Marie Josephine Kama Dabany (born 22 January 1941; member of the Order of Gabriela Silang), also known by the names Marie Joséphine Kama and Josephine Bongo, is a Gabonese singer and musician. Dabany served as the First Lady of Gabon from 1967 to 1987. For 28 years, she was married to Omar Bongo Ondimba, who was President of Gabon from 1967 to 2009. After their divorce, she successfully pursued a career in music. She is the mother of the former President of Gabon, Ali Bongo Ondimba.`,
    //   link: "https://en.wikipedia.org/wiki/Patience_Dabany",
    // },
    {
      country: "Trinidad and Tobago",
      description: `Slinger Francisco ORTT CM OBE (born July 9, 1935), better known as Mighty Sparrow, is a Trinidadian calypso vocalist, songwriter, and guitarist. Known as the "Calypso King of the World", he is one of the best-known and most successful calypsonians. He has won Trinidad's Carnival Road March competition eight times, Calypso King/Monarch eight times, and has twice won the Calypso King of Kings title.`,
      link: "https://en.wikipedia.org/wiki/Mighty_Sparrow",
      hint: `Known as the "Calypso King of the World" and one of the most successful calypsonians.`,
    },
    {
      country: "Senegal",
      description: `Youssou N'Dour (French: [jusu (ɛ)nduʁ], Wolof: Yuusu Nduur; also known as Youssou Madjiguène Ndour; born 1 October 1959) is a Senegalese singer, songwriter, musician, composer, occasional actor, businessman, and politician. In 2004, Rolling Stone described him as, "perhaps the most famous singer alive" in Senegal and much of Africa and in 2023, the same publication ranked him at number 69 on its list of the 200 Greatest Singers of All Time. From April 2012 to September 2013, he was Senegal's Minister of Tourism.`,
      link: "https://en.wikipedia.org/wiki/Youssou_N%27Dour",
      hint: `Described by Rolling Stone as "perhaps the most famous singer alive" in his country and much of Africa.`,
    },
    {
      country: "North Korea",
      description: `The Moranbong Band (Korean: 모란봉악단; MR: Moranbong aktan, lit. "Tree Peony Peak Band"), also known as the Moran Hill Orchestra, is a North Korean girl group. The original members were selected by the country's supreme leader, Kim Jong Un. Performing interpretive styles of pop, rock, and fusion, they are the first all-female band from the DPRK, and made their world debut on 6 July 2012. Their varied musical style has been described as symphonic because it is "putting together different kinds of sounds, and ending in a harmonious, pleasing result." \n The band has been referred to in the West as "North Korea's version of the Spice Girls".`,
      link: "https://en.wikipedia.org/wiki/Moranbong_Band",
      hint: `An all-female band referred to in the West as "this country's version of the Spice Girls".`,
    },
    {
      country: "Dominican Republic",
      description: `The music and dance of merengue originated in the Dominican Republic in the 19th century. Initially merengue was a rural form that was considered unsophisticated and was out of favor with the upper classes. As the result of a political decree in the 1940s, however, it was “dressed up” and became the Dominican Republic’s national dance. This collection features Dominican Joaquín Díaz on diatonic accordion and vocals playing traditional and original merengue music. “Homenaje a San Pedro” is his salute to baseball players Pedro Guerrero, Jorge Bell, and Sammy Sosa, some of the people who were born in his hometown of San Pedro de Macoris.`,
      link: "https://folkways.si.edu/joaquin-diaz/merengue-alegre/world/music/album/smithsonian",
      hint: `The national dance of this country, originating in the 19th century and popularized in the 1940s.`,
    },
    {
      country: "Kuwait",
      description: `Nawal was born on November 18 in the mid-1960s in the Eastern region. She began her studies at the Higher Institute for Musical Arts in Kuwait in the 1970s, where she developed a passion for art and music. Her entry into the institute was driven by her love for music, with Umm Kulthum, Abdel Halim Hafez, Warda Al-Jazairia, and Fairuz being among her favorite singers. Nawal first took the stage at the age of seventeen, during her high school years. She did not continue her university education due to her involvement in the arts and faced difficulties, including delays in obtaining Kuwaiti nationality, which was a significant obstacle at the start of her career. Being stateless, she studied various subjects at the institute, such as solfège, and everything related to singing, including maqams, musical notes, melodies, and vocal ranges like pitch, timbre, and volume, as well as music dictation to develop her musical sense and strengthen her skills in identifying and distinguishing between notes and classifying global musical genres. During her time at the institute, her interaction with the art scene began as many singers who recognized her talent recommended her, including Talal Maddah and Abdul Karim Abdul Qadir. In 1983, the musician Rashid Al-Khader insisted she sing, eventually convincing her, and he is credited with her emergence on the art scene. "Musical instruments Nawal excels at playing."`,
      link: "https://ar.wikipedia.org/wiki/%D9%86%D9%88%D8%A7%D9%84_%D8%A7%D9%84%D9%83%D9%88%D9%8A%D8%AA%D9%8A%D8%A9",
      hint: `A famous singer from the Arab region who began her career in the 1980s.`,
    },
    {
      country: "Djibouti",
      description: `Ostinato Records founder Vik Sohonie traveled to Djibouti in hopes of shedding light on the tiny East African nation’s widely unknown musical heritage—much like he did for Somalia with Sweet as Broken Dates, the label’s revelatory 2017 collection of hidden sounds from that country. Instead, he ended up recording Groupe RTD, a contemporary Djibouti combo that serves as house band for Radiodiffusion-Télévision Djibouti, the country’s nationalized media source. The Dancing Devils of Djibouti captures the nine-piece group off the clock, as it were, blowing hard and free.`,
      link: "https://daily.bandcamp.com/album-of-the-day/groupe-rtd-the-dancing-devils-of-djibouti-review",
      hint: `A contemporary musical group from a tiny East African nation.`,
    },
    {
      country: "Taiwan",
      description: `Chen Da (also Chen Ta; Chinese: 陳達; pinyin: Chén Dá; Wade–Giles: Ch'en2 Ta2; Pe̍h-ōe-jī: Tân Ta̍t; b. 1905 (1906?) – d. April 11, 1981) was a Taiwanese folk singer. He was part of Taiwan's folk music scene and worked as an analphabetic creator of lyrics. His spontaneous performances of traditional tunes became an object of study for many scholars focused on the music of Taiwan and brought him to the attention of writers engaged in music criticism including the novelist Wang Tuoh. According to the Journal of Music in China, Chen Da was "the only noted singer of Taiwanese folk singing." Chen Da is also referred to as a singer of "Hoklo folk songs," a synonym of "Taiwanese folk songs."`,
      link: "https://en.wikipedia.org/wiki/Chen_Da_(singer)",
      hint: `A renowned folk singer who was part of this country's folk music scene and worked as an analphabetic creator of lyrics.`,
    },
    {
      country: "Guinea-Bissau",
      description: `Eneida Marta is a singer from Guinea-Bissau who sings in Portuguese and Guinea-Bissau Creole. She has toured internationally since the release of her first album in 2001 and was selected to take part in the 2008 World Music Expo (WOMEX). Her most recent album, Family, was released in 2022 and has ranked in the top 20 of the Transglobal World Music Chart.`,
      link: "https://en.wikipedia.org/wiki/Eneida_Marta",
      hint: `A singer from this West African country known for singing in Portuguese and Creole.`,
    },
    {
      country: "Ethiopia",
      description: `Aster Aweke (Amharic: አስቴር አወቀ; born 1959) is an Ethiopian singer-songwriter. Aster's voice has attracted broader public popularity, especially tracing back in 1990s singles and her single "Abebayehosh" in Ethiopian New Year. She is best known for her 1999 album Hagere and her 2006 album Fikir.`,
      link: "https://en.wikipedia.org/wiki/Aster_Aweke",
      hint: `A singer-songwriter known for her popular singles and albums in the 1990s and 2000s.`,
    },
    {
      country: "Cambodia",
      description: `"Champa Battambang" (ចំប៉ាបាត់ដំបង, which means "The Frangipani of Battambang”) is a popular song of the 1960s composed by Sinn Sisamouth, which has become part of Cambodian heritage.`,
      link: "https://en.wikipedia.org/wiki/Champa_Battambang",
      hint: `The title which literally translates as the frangipani of Battambang refers to a flower commonly seen in this city in this Southeast Asian country.`,
    },
    // {
    //   country: "",
    //   description: ``,
    //   link: "",
    // },
    // Add more locations as needed
  ];
};

export default getDescriptionHintOptions;
